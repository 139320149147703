import { Show, Suspense } from 'solid-js';
import { useStripe } from '../../../../providers/stripe';
import { PaymentElement } from './elements/payment';

export function Elements() {
	const { elements } = useStripe();

	return (
		<Suspense>
			<Show when={elements()}>{(elements) => <PaymentElement elements={elements()} />}</Show>
		</Suspense>
	);
}
